
    import { ProductDetailModel } from "@/apiManager/product/productData";
    import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

    @Component
    export default class PopUpProductDescription extends Vue
    {
        @PropSync("open", { type: Boolean }) private syncedOpen!: boolean;
        @Prop({ type: String }) private readonly description!: string;

        get getTheme(): string
        {
            return this.$tStore.state.configState.setting.license.theme;
        }

        get getTitle(): string
        {
            let product: ProductDetailModel = this.$tStore.state.productState.productDetail;
            if (product)
            {
                return this.translateValue(product.name);
            }
            else
            {
                return "";
            }
        }
    }
