
    import LanguageModel from "@/apiManager/_common/languageModel";
    import { Component, Prop, Vue } from "vue-property-decorator";

    @Component
    export default class ProductSection extends Vue
    {
        //#region Components
        //#endregion

        //#region Props
        @Prop({ type: Number }) private readonly index!: number;
        @Prop({ type: Object }) private readonly title!: LanguageModel;
        @Prop({ type: Object }) private readonly content!: LanguageModel;
        @Prop({ type: Boolean }) private readonly visible!: boolean;
        //#endregion

        //#region Data
        //#endregion

        //#region Getters

        get g_Title(): string
        {
            return this.getUseMainLanguage ? this.title.lang1 : this.title.lang2;
        }

        get g_Content(): string
        {
            return this.getUseMainLanguage ? this.content.lang1 : this.content.lang2;
        }

    //#endregion

    //#region Functions
    //#endregion

    //#region Events
    onShown()
    {
        let container = document.querySelector("#product-selection-" + this.index);
        if (container)
        {
            let topOfElement: number = (container as HTMLElement).offsetTop - 44;
            window.scroll({ top: topOfElement, behavior: "smooth" });
        }
    }
    //#endregion

    //#region Hooks
    //#endregion

    //#region Watch
    //#endregion
    }
