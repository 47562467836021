
    import { Component, Prop, Mixins } from "vue-property-decorator";
    import BeforeRouteEnterMixin from "@/mixins/beforeRouteEnterMixin";
    import { Route, RawLocation } from "vue-router";
    import moment from "moment";
    import PopupProductDates from "@/app/product/components/PopupProductDates/PopupProductDates.vue";
    import ProductPayload from "@/store/modules/product/models";
    import RoutesEnum from "@/router/routesEnum";
    import { VueAgile } from "vue-agile";
    import FormatMoney from "@/app/common/components/FormatMoney.vue";
    import PopUpProductDescription from "@/app/product/components/PopUpProductDescription.vue";
    import ProductSection from "./ProductSection.vue";
    import { PreviewClientQuery } from "@/models/previewClientQuery";
    import { SettingAgencyLanguageModel, SettingModel } from "@/apiManager/setting/settingData";
    import { ProductDetailModel, ProductDetailProductGroupModel, ProductDetailProductPriceCategoryModel, ProductDetailProductPriceClassModel } from "@/apiManager/product/productData";
    import ProductDetailSelection from "@/models/productDetail/productDetailSelection";
    import { ProductDisplayModeEnum, ProductFileRoomingTypeEnum, RezToursLanguageEnum } from "@/apiManager/_common/enums";

    //#region validateQuery
    let validateQuery = (p_Query: {
	[key: string]: string | (string | null)[];
    }): boolean =>
    {
        if (
            !p_Query.prod ||
            !p_Query.prodPDat ||
            !p_Query.dtDep ||
            !p_Query.dtRet
        )
        {
            return false;
        }
        if (
            p_Query.prod.length === 0 ||
            p_Query.prodPDat.length === 0 ||
            p_Query.dtDep.length === 0 ||
            p_Query.dtRet.length === 0
        )
        {
            return false;
        }
        if (parseInt((p_Query.prodPDat as string) || "") < 1)
        {
            return false;
        }
        if (
            !moment(p_Query.dtDep as string).isValid() ||
            !moment(p_Query.dtRet as string).isValid()
        )
        {
            return false;
        }

        return true;
    };
//#endregion

    @Component
    export default class ProductDetail extends Mixins(BeforeRouteEnterMixin)
    {
        get components()
        {
            let components = {
                "popup-product-dates": PopupProductDates,
                "agile": VueAgile,
                "format-money": FormatMoney,
                "popup-product-description": PopUpProductDescription,
                "product-section": ProductSection
            };

            return components;
        }

        @Prop({ type: String, default: "" }) private readonly productGroupSelected!: string;

        private product: string = "";
        private productPriceDateCode: number = 0;
        private dateDepart: string = "";
        private dateReturn: string = "";

        private classSelected: ProductDetailProductPriceClassModel = new ProductDetailProductPriceClassModel();
        private categorySelected: ProductDetailProductPriceCategoryModel = new ProductDetailProductPriceCategoryModel();
        private nbAdult: number = 1;
        private nbChild: number = 0;

        private popupDatesOpen: boolean = false;
        private popupDescriptionOpen: boolean = false;

        private busPickupSelected: number = 0;

        // Carroussel ---------------------------------------------------------------------------------------------------------
        slide = 0;
        sliding: boolean = false;
        asNavFor1: any[] = [];
        asNavFor2: any[] = [];
        options1 =
        {
            autoplay: true,
            dots: false,
            fade: true,
            navButtons: false,
            pauseOnHover: true
        };

        options2 =
        {
             autoplay: false,
             centerMode: true,
             dots: false,
             navButtons: false,
             slidesToShow: 4,
             pauseOnHover: true,
             responsive: [
             {
             breakpoint: 600,
             settings: {
                 slidesToShow: 5 } },
             {
             breakpoint: 1000,
             settings: {
                 navButtons: true } }]
        };

        // ---------------------------------------------------------------------------------------------------------

        get getSetting(): SettingModel
        {
            return this.$tStore.state.configState.setting;
        }

        get g_Detail(): ProductDetailModel
        {
            return this.$tStore.state.productState.productDetail;
        }

        get slideShowControl()
        {
            if(this.g_Detail.listImage.length > 1)
            {
                return true;
            }
            else
            {
                return false;
            }
        }

        get getProductGroupSelected(): ProductDetailProductGroupModel[]
        {
            if (this.productGroupSelected.length > 0)
            {
                return this.g_Detail.listProductGroup.filter((group) =>
                {
                    return group.code === parseInt(this.productGroupSelected);
                });
            }
            else
            {
                return this.g_Detail.listProductGroup;
            }
        }

        get getDescription(): string
        {
            if ((this.g_Detail) && (this.g_Detail.description))
            {
                return this.translateValue(this.g_Detail.description);
            }
            else
            {
                return "";
            }
        }

        get getDescriptionMoreInfo(): string
        {
            if ((this.g_Detail) && (this.g_Detail.moreInfo))
            {
                return this.translateValue(this.g_Detail.moreInfo);
            }
            else
            {
                return "";
            }
        }

        onClickBack()
        {
            this.$router.push({ name: RoutesEnum.Package });
        }

        get getDaysNames(): string[]
        {
            return this.$tStore.getters.getConfigLanguage.days_Abbr;
        }

        get g_DetailIsLoading(): boolean
        {
            return this.$tStore.state.productState.productDetailIsLoading;
        }

        get getAvailabilityLabel(): RezToursLanguageEnum
        {
            if (this.g_Detail.fileRoomingType === ProductFileRoomingTypeEnum.Basic)
            {
                return this.getRezToursLanguageEnum.availablePlaces;
            }
            else
            {
                return this.getRezToursLanguageEnum.availableRooms;
            }
        }

        get getNbRoom(): number
        {
            if (this.categorySelected.pricingCategory.nbPax > 0)
            {
                return this.nbAdult / this.categorySelected.pricingCategory.nbPax;
            }
            else
            {
                return 1;
            }
        }

        get getIsChildrenInvalid(): boolean
        {
            return (this.nbChild > 0) && (this.getNbRoom > 1);
        }

        get getIsInventoryInvalid(): boolean
        {
            if ((this.classSelected.inventory > -1) && (this.classSelected.inventory < this.getNbRoom))
            {
                return true;
            }

            return false;
        }

        get getCategories(): ProductDetailProductPriceCategoryModel[]
        {
            if (this.classSelected.code)
            {
                return this.classSelected.listCategory;
            }
            else
            {
                return [];
            }
        }

        get getHasChild(): boolean
        {
            let hasChild = this.getCategories.some((category) =>
            {
                return category.pricingCategory.isChild;
            });

            if (!hasChild)
            {
                this.nbChild = 0;
            }

            return hasChild;
        }

        get getRoomSelectionList(): RoomSelection[]
        {
            let roomSelectionList: RoomSelection[] = [];
            this.getCategories.forEach((category) =>
            {
                if (!category.pricingCategory.isChild)
                {
                    if (this.nbAdult % category.pricingCategory.nbPax === 0)
                    {
                        let nbRooms = this.nbAdult / category.pricingCategory.nbPax;
                        let label = nbRooms.toString() + " " + this.i18n(this.getRezToursLanguageEnum.rooms) + " (" + this.translateValue(category.pricingCategory.name) + ")";
                        roomSelectionList.push(new RoomSelection(category, label));
                    }
                }
            });

            if (roomSelectionList.length > 0)
            {
                let tmpRoom = roomSelectionList.reduce( (prev, current) =>
                {
                    return (prev.value.pricingCategory.nbPax > current.value.pricingCategory.nbPax) ? prev : current;
                }).value;

                this.categorySelected = tmpRoom;
            }

            return roomSelectionList;
        }

        get getSelectionTotal(): number
        {
            let priceAdult: number = this.categorySelected.total;
            let priceChild: number = 0;
            this.getCategories.forEach((category) =>
            {
                if (category.pricingCategory.isChild)
                {
                    priceChild = category.total;
                }
            });

            return (priceAdult * this.nbAdult) + (priceChild * this.nbChild);
        }

        get getSelectionDeposit(): number
        {
            let priceAdult: number = this.categorySelected.deposit;
            let priceChild: number = 0;
            this.getCategories.forEach((category) =>
            {
                if (category.pricingCategory.isChild)
                {
                    priceChild = category.deposit;
                }
            });

            return (priceAdult * this.nbAdult) + (priceChild * this.nbChild);
        }

        get getClassesSorted(): ProductDetailProductPriceClassModel[]
        {
            return this.g_Detail.listClass.sort( (a: ProductDetailProductPriceClassModel, b: ProductDetailProductPriceClassModel) =>
            {
                return this.minPriceFromProductPriceClass(a) - this.minPriceFromProductPriceClass(b);
            });
        }

        get getDurationAndType(): string
        {
            if (this.g_Detail.isDurationInNights)
            {
                return (this.g_Detail.duration - 1) + " " + this.i18n(this.getRezToursLanguageEnum.nights);
            }
            else
            {
                return this.g_Detail.duration + " " + this.i18n(this.getRezToursLanguageEnum.days);
            }
        }

        get getIsDisplayModeTest(): boolean
        {
            return this.g_Detail.displayMode === ProductDisplayModeEnum.Test;
        }

        get g_FlyerName(): string
        {
            return (this.getUseMainLanguage ? this.g_Detail?.flyer?.lang1 : this.g_Detail?.flyer?.lang2) ?? "";
        }

        get g_HasFlyer(): boolean
        {
            return this.g_FlyerName.length > 0;
        }

        get g_ShowSelectRoom()
        {
            let result: boolean = false;

            let indexClass = 0;
            let indexCategory = 0;
            let classe: ProductDetailProductPriceClassModel | null = null;
            let category: ProductDetailProductPriceCategoryModel | null = null;
            for (indexClass = 0; indexClass < this.g_Detail.listClass.length; indexClass++)
            {
                classe = this.g_Detail.listClass[indexClass];
                for (indexCategory = 0; indexCategory < classe.listCategory.length; indexCategory++)
                {
                    category = classe.listCategory[indexCategory];
                    if (category.pricingCategory.nbPax > 1 && !category.pricingCategory.isChild)
                    {
                        result = true;
                        break;
                    }
                }

                if (result)
                {
                    break;
                }
            }

            return result;
        }

        get g_HasBusPickup(): boolean
        {
            return this.g_Detail.listBusPickup.length > 0;
        }

        minPriceFromProductPriceClass(p_Class: ProductDetailProductPriceClassModel): number
        {
            let minProductPriceCategory = p_Class.listCategory.reduce( (prev: number, curr: ProductDetailProductPriceCategoryModel): number =>
            {
                if (curr.pricingCategory.isChild)
                {
                    return prev;
                }
                return prev < curr.price ? prev : curr.price;
            }, 0);
            return minProductPriceCategory;
        }

        generateMoment(value: string)
        {
            return moment(value);
        }

        SetUrlImage(p_ImageUrl: string)
        {
            return "background-image: url(" + p_ImageUrl + "); ";
        }

        onChangeAdults(p_Diff: number)
        {
            this.nbAdult = Math.min(Math.max(this.nbAdult + p_Diff, 1), 8);
        }

        onChangeChildren(p_Diff: number)
        {
            this.nbChild = Math.min(Math.max(this.nbChild + p_Diff, 0), 4);
        }

        onSlideStart(slide: any)
        {
            this.sliding = true;
        }

        onSlideEnd(slide: any)
        {
            this.sliding = false;
        }

        onFlyerClick()
        {
            let lang: SettingAgencyLanguageModel = this.getUseMainLanguage ? this.$tStore.state.configState.setting.agency.lang1 : this.$tStore.state.configState.setting.agency.lang2;
            this.$apiManager.product.getFlyer(this.product, this.g_FlyerName, lang.code);
        }

        beforeRouteEnter(
            p_To: Route,
            p_From: Route,
            p_Next: (to?: RawLocation | false) => void
        ): any
        {
            if (validateQuery(p_To.query))
            {
                p_Next();
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        async beforeRouteUpdate(
            p_To: Route,
            p_From: Route,
            p_Next: (to?: RawLocation | false) => void
        )
        {
            if (validateQuery(p_To.query))
            {
                let previousCategory: ProductDetailProductPriceCategoryModel = this.categorySelected;
                this.product = (p_To.query.prod as string) || "";
                this.productPriceDateCode = parseInt(
                    (p_To.query.prodPDat as string) || ""
                );
                this.dateDepart = (p_To.query.dtDep as string) || "";
                this.dateReturn = (p_To.query.dtRet as string) || "";

                let payload = new ProductPayload.Actions.FetchProductDetail(
                    this.product,
                    this.productPriceDateCode,
                    this.dateDepart,
                    this.dateReturn
                );

                try
                {
                    await this.$tStore.dispatch(payload);

                    this.$nextTick(() =>
                    {
                        if (this.g_Detail.listClass.length > 0)
                        {
                            let classIndex: number = this.g_Detail.listClass.findIndex( (_class) =>
                            {
                                return _class.pricingClass.code === this.classSelected.code;
                            });
                            if (classIndex > -1)
                            {
                                this.classSelected = this.g_Detail.listClass[classIndex];
                            }
                            else
                            {
                                this.classSelected = this.g_Detail.listClass[0];
                            }
                        }

                        this.$nextTick(() =>
                        {
                            let classIndex: number = this.getCategories.findIndex( (_category) =>
                            {
                                return _category.pricingCategory.code === previousCategory.pricingCategory.code;
                            });
                            if (classIndex > -1)
                            {
                                this.categorySelected = this.getCategories[classIndex];
                            }
                            else
                            {
                                this.categorySelected = this.getCategories[0];
                            }
                        });
                    });
                    p_Next();
                }
                catch
                {
                    return p_Next({
                        name: RoutesEnum.NotFound,
                        replace: true
                    });
                }
            }
            else
            {
                p_Next({ name: RoutesEnum.Package });
            }
        }

        beforeRouteLeave(
            p_To: Route,
            p_From: Route,
            p_Next: (to?: RawLocation | false) => void)
        {
            let productDetailSelection: ProductDetailSelection = new ProductDetailSelection();
            productDetailSelection.nbAdults = this.nbAdult;
            productDetailSelection.nbChildren = this.nbChild;
            productDetailSelection.classCode = this.classSelected.pricingClass.code;
            productDetailSelection.categoryCode = this.categorySelected.pricingCategory.code;

            this.$tStore.commit(new ProductPayload.Mutations.SetProductDetailSelection(productDetailSelection));
            p_Next();
        }

        onTravelBagClick()
        {
            if (this.g_Detail.displayMode === ProductDisplayModeEnum.Test)
            {
                return;
            }

            if (this.getIsChildrenInvalid)
            {
                return;
            }

            if (this.getIsInventoryInvalid)
            {
                return;
            }
            let queryData: PreviewClientQuery = {
                prod: this.g_Detail.product,
                prodPDat: this.g_Detail.productPriceDateCode,
                prodPCla: this.classSelected.code,
                prodPCat: this.categorySelected.code,
                pricCla: this.classSelected.pricingClass.code,
                pricCat: this.categorySelected.pricingCategory.code,
                dtDep: moment(this.g_Detail.dateFrom).format(
                    "YYYY-MM-DD"
                ),
                dtRet: moment(this.g_Detail.dateTo).format(
                    "YYYY-MM-DD"
                ),
                nbA: this.nbAdult,
                nbC: this.nbChild,
                nbR: this.getNbRoom,
                pickup: this.busPickupSelected,
                hasOpt: this.classSelected.hasOption,
                opt: []
            };

            if (this.classSelected.hasOption)
            {

                this.$router.push({
                    name: RoutesEnum.Options,
                    query: {
                        data: encodeURIComponent(btoa(JSON.stringify(queryData)))
                    },
                    params: {
                        productGroupSelected: this.productGroupSelected
                    }
                });
            }
            else
            {
                this.$router.push({
                    name: RoutesEnum.TravelBag,
                    query: {
                        data: encodeURIComponent(btoa(JSON.stringify(queryData)))
                    },
                    params: {
                        productGroupSelected: this.productGroupSelected
                    }
                });
            }
        }

        onClickOpenPopupDates()
        {
            this.popupDatesOpen = true;
        }

        onClickOpenDescriptionProduct()
        {
            this.popupDescriptionOpen = true;
        }

        mounted()
        {
            if (this.$refs.thumbnails && this.$refs.main)
            {
                this.asNavFor1.push(this.$refs.thumbnails);
                this.asNavFor2.push(this.$refs.main);
            }
        }

        beforeMount()
        {
            this.product = (this.$route.query.prod as string) || "";
            this.productPriceDateCode = parseInt(
                (this.$route.query.prodPDat as string) || ""
            );
            this.dateDepart = (this.$route.query.dtDep as string) || "";
            this.dateReturn = (this.$route.query.dtRet as string) || "";

            let payload = new ProductPayload.Actions.FetchProductDetail(
                this.product,
                this.productPriceDateCode,
                this.dateDepart,
                this.dateReturn
            );

            this.fetchProductDetail(payload);

        }

        async fetchProductDetail(p_Payload: ProductPayload.Actions.FetchProductDetail)
        {
            try
            {
                await this.$tStore.dispatch(p_Payload);

                if (this.g_Detail.listBusPickup.length > 0)
                {
                    this.busPickupSelected = this.g_Detail.listBusPickup[0]?.code ?? 0;
                }

                if (this.g_Detail.listClass.length > 0)
                {
                    this.classSelected = this.g_Detail.listClass[0];
                }

                let productDetailSelection: ProductDetailSelection = this.$tStore.state.productState.productDetailSelection;

                if (productDetailSelection.nbAdults > 0)
                {
                    this.initFromProductDetailSelection(productDetailSelection);
                }
                else
                {
                    let tmpCategorySelected: ProductDetailProductPriceCategoryModel = this.categorySelected;
                    this.g_Detail.listClass.forEach( (elementClass) =>
                    {
                        if (elementClass.inventory > 0 || elementClass.inventory === -1)
                        {
                            let tmpCategoryLow : ProductDetailProductPriceCategoryModel | null = null;
                            let tmpCategoryDouble = elementClass.listCategory.find( (element) =>
                            {
                                return element.pricingCategory.name.lang1 === "Double";
                            });

                            if (tmpCategoryDouble)
                            {
                                tmpCategoryLow = tmpCategoryDouble;
                            }
                            else
                            {
                                let category: ProductDetailProductPriceCategoryModel;
                                for (category of elementClass.listCategory)
                                {
                                    if (!category.pricingCategory.isChild)
                                    {
                                        if ((tmpCategoryLow === null) || (tmpCategoryLow.price === 0) || (tmpCategoryLow.price > category.price))
                                        {
                                            tmpCategoryLow = category;
                                        }
                                    }
                                }
                            }

                            if (tmpCategoryLow !== null)
                            {
                                if (tmpCategorySelected.price === 0|| tmpCategoryLow.price < tmpCategorySelected.price)
                                {
                                    tmpCategorySelected = tmpCategoryLow;
                                }
                            }
                        }
                    });

                    this.classSelected = this.g_Detail.listClass.find( (elementClass) =>
                    {
                        return elementClass.listCategory.findIndex( (elementCategory) =>
                        {
                            return elementCategory === tmpCategorySelected;
                        }) > -1;
                    }) || this.g_Detail.listClass[0];

                    this.nbAdult = tmpCategorySelected.pricingCategory.nbPax;

                    this.$nextTick(() =>
                    {
                        this.categorySelected = tmpCategorySelected;
                    });
                }

            }
            catch
            {
                this.$router.replace({
                    name: RoutesEnum.NotFound
                });
            }
        }

        initFromProductDetailSelection(p_ProductDetailSelection: ProductDetailSelection)
        {
            let classIndex: number = this.g_Detail.listClass.findIndex( (_class) =>
            {
                return _class.pricingClass.code === p_ProductDetailSelection.classCode;
            });

            if (classIndex > -1)
            {
                this.classSelected = this.g_Detail.listClass[classIndex];
            }
            else
            {
                this.classSelected = this.g_Detail.listClass[0];
            }

            this.nbAdult = p_ProductDetailSelection.nbAdults;
            this.nbChild = p_ProductDetailSelection.nbChildren;

            this.$nextTick(() =>
            {
                let categoryIndex: number = this.getCategories.findIndex( (_category) =>
                {
                    return _category.pricingCategory.code === p_ProductDetailSelection.categoryCode;
                });

                if (categoryIndex > -1)
                {
                    this.categorySelected = this.getCategories[categoryIndex];
                }
                else
                {
                    let tmpRoom = this.getCategories.reduce( (prev, current) =>
                    {
                        return (prev.pricingCategory.nbPax > current.pricingCategory.nbPax) ? prev : current;
                    });

                    this.categorySelected = tmpRoom;
                }
            });
        }
    }

    class RoomSelection
    {
        value: ProductDetailProductPriceCategoryModel;
        label: string;

        constructor(p_Value: ProductDetailProductPriceCategoryModel, p_Label: string)
        {
            this.value = p_Value;
            this.label = p_Label;
        }
    }
